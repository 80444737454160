var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"top":true,"color":_vm.color,"timeout":6000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[(_vm.snackbar_msg != null)?_c('span',[_vm._v(_vm._s(_vm.snackbar_msg))]):_vm._e(),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1),_c('v-row',[_c('div',{staticStyle:{"position":"fixed","z-index":"1","background-color":"white","width":"100%","top":"55px"}},[_c('v-breadcrumbs',{staticStyle:{"font-weight":"600","letter-spacing":"1px"},attrs:{"items":_vm.items,"large":""}})],1)]),_c('br'),_c('br'),_c('br'),_c('section',{staticClass:"bg-color-"},[_c('div',{staticClass:"container"},[[(_vm.init_loading)?_c('div',[_c('v-skeleton-loader',_vm._b({attrs:{"type":"three-line,  article"}},'v-skeleton-loader',_vm.attrs,false))],1):_c('div',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),[_c('download-excel',{attrs:{"data":_vm.orgBookslist,"fields":_vm.all_org,"worksheet":"Books Data","name":"Books Data.xls"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","size":"40","left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-file-excel")])]}}])},[_c('span',[_vm._v("Import to Excel")])])],1)]],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orgBookslist,"search":_vm.search},scopedSlots:_vm._u([{key:"item.totslBooks",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"gray","dark":""},on:{"click":function($event){return _vm.orgbooksdetails(item)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.totslBooks)+" ")])]}}],null,true)},[_c('span',[_vm._v(" View Details")])])]}},{key:"item.Dept_Count",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"gray","dark":""},on:{"click":function($event){return _vm.getorgdeptdetails(item)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.Dept_Count)+" ")])]}}],null,true)},[_c('span',[_vm._v(" View Details")])])]}},{key:"item.Series_Count",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"gray","dark":""},on:{"click":function($event){return _vm.getorgseriesdetails(item)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.Series_Count)+" ")])]}}],null,true)},[_c('span',[_vm._v(" View Details")])])]}}])})],1)],1)]],2)]),[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog_details),callback:function ($$v) {_vm.dialog_details=$$v},expression:"dialog_details"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"gray"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog_details = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.org_name))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialog_details = false}}},[_vm._v(" Close ")])],1)],1),_c('br'),_c('v-card-text',[_c('v-card',[[(_vm.init_loading)?_c('div',{staticClass:"text-center"},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"three-line,  article"}},'v-skeleton-loader',_vm.attrs,false))],1):_vm._e()],(_vm.init_loading == false)?_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_book),callback:function ($$v) {_vm.search_book=$$v},expression:"search_book"}}),_c('v-spacer'),[_c('download-excel',{attrs:{"data":_vm.orgbookdetails,"fields":_vm.single_org,"worksheet":"Books Data","name":"Books Data.xls"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","size":"40","left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-file-excel")])]}}],null,false,1930435796)},[_c('span',[_vm._v("Export to Excel")])])],1)]],2):_vm._e(),(_vm.init_loading == false)?_c('v-data-table',{attrs:{"headers":_vm.header_org,"items":_vm.orgbookdetails,"search":_vm.search_book},scopedSlots:_vm._u([{key:"item.date_of_entry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_of_entry.split("T")[0].split("-")[2] + "-" + item.date_of_entry.split("T")[0].split("-")[1] + "-" + item.date_of_entry.split("T")[0].split("-")[0])+" ")]}}],null,false,3544867317)}):_vm._e()],2)],1)],1)],1)],1)],[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog_details_series),callback:function ($$v) {_vm.dialog_details_series=$$v},expression:"dialog_details_series"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"gray"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.series_close_data()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.org_name))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.series_close_data()}}},[_vm._v(" Close ")])],1)],1),_c('br'),_c('v-card-text',[_c('v-card',[[(_vm.init_loading)?_c('div',{staticClass:"text-center"},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"three-line,  article"}},'v-skeleton-loader',_vm.attrs,false))],1):_vm._e()],(_vm.init_loading == false)?_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_book),callback:function ($$v) {_vm.search_book=$$v},expression:"search_book"}}),_c('v-spacer'),[_c('download-excel',{attrs:{"data":_vm.orgbookdetails,"fields":_vm.single_org,"worksheet":"Books Data","name":"Books Data.xls"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","size":"40","left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-file-excel")])]}}],null,false,1930435796)},[_c('span',[_vm._v("Export to Excel")])])],1)]],2):_vm._e(),(_vm.init_loading == false)?_c('v-data-table',{attrs:{"headers":_vm.header_org,"items":_vm.orgbookdetails,"search":_vm.search_book},scopedSlots:_vm._u([{key:"item.date_of_entry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_of_entry.split("T")[0].split("-")[2] + "-" + item.date_of_entry.split("T")[0].split("-")[1] + "-" + item.date_of_entry.split("T")[0].split("-")[0])+" ")]}}],null,false,3544867317)}):_vm._e()],2)],1)],1)],1)],1)],[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog_details_dept),callback:function ($$v) {_vm.dialog_details_dept=$$v},expression:"dialog_details_dept"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"gray"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.dept_close_data()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.org_name))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.dept_close_data()}}},[_vm._v(" Close ")])],1)],1),_c('br'),_c('v-card-text',[_c('v-card',[[(_vm.init_loading)?_c('div',{staticClass:"text-center"},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"three-line,  article"}},'v-skeleton-loader',_vm.attrs,false))],1):_vm._e()],(_vm.init_loading == false)?_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_book),callback:function ($$v) {_vm.search_book=$$v},expression:"search_book"}}),_c('v-spacer'),[_c('download-excel',{attrs:{"data":_vm.orgbookdetails,"fields":_vm.single_org,"worksheet":"Books Data","name":"Books Data.xls"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","size":"40","left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-file-excel")])]}}],null,false,1930435796)},[_c('span',[_vm._v("Export to Excel")])])],1)]],2):_vm._e(),(_vm.init_loading == false)?_c('v-data-table',{attrs:{"headers":_vm.header_org,"items":_vm.orgbookdetails,"search":_vm.search_book},scopedSlots:_vm._u([{key:"item.date_of_entry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_of_entry.split("T")[0].split("-")[2] + "-" + item.date_of_entry.split("T")[0].split("-")[1] + "-" + item.date_of_entry.split("T")[0].split("-")[0])+" ")]}}],null,false,3544867317)}):_vm._e()],2)],1)],1)],1)],1)],[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog_department),callback:function ($$v) {_vm.dialog_department=$$v},expression:"dialog_department"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"gray"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog_department = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.org_name))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialog_department = false}}},[_vm._v(" Close ")])],1)],1),_c('br'),_c('v-card-text',[_c('v-card',[[(_vm.init_loading)?_c('div',{staticClass:"text-center"},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"three-line,  article"}},'v-skeleton-loader',_vm.attrs,false))],1):_vm._e()],(_vm.init_loading == false)?_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_book),callback:function ($$v) {_vm.search_book=$$v},expression:"search_book"}}),_c('v-spacer'),[_c('download-excel',{attrs:{"data":_vm.orgdeptdetails,"fields":_vm.dept_book_count_excel,"worksheet":"Books Data","name":"Books Data.xls"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","size":"40","left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-file-excel")])]}}],null,false,1930435796)},[_c('span',[_vm._v("Export to Excel")])])],1)]],2):_vm._e(),(_vm.init_loading == false)?_c('v-data-table',{attrs:{"headers":_vm.headers_department,"items":_vm.orgdeptdetails,"search":_vm.search_book},scopedSlots:_vm._u([{key:"item.Book_count",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"gray","dark":""},on:{"click":function($event){return _vm.getorgdeptdata(item)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.Book_count)+" ")])]}}],null,true)},[_c('span',[_vm._v(" View Details")])])]}}],null,false,907118535)}):_vm._e()],2)],1)],1)],1)],1)],[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog_series),callback:function ($$v) {_vm.dialog_series=$$v},expression:"dialog_series"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"gray"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog_series = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.org_name))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialog_series = false}}},[_vm._v(" Close ")])],1)],1),_c('br'),_c('v-card-text',[_c('v-card',[[(_vm.init_loading)?_c('div',{staticClass:"text-center"},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"three-line,  article"}},'v-skeleton-loader',_vm.attrs,false))],1):_vm._e()],(_vm.init_loading == false)?_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_book),callback:function ($$v) {_vm.search_book=$$v},expression:"search_book"}}),_c('v-spacer'),[_c('download-excel',{attrs:{"data":_vm.orgseriesdetails,"fields":_vm.series_book_count_excel,"worksheet":"Books Data","name":"Books Data.xls"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","size":"40","left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-file-excel")])]}}],null,false,1930435796)},[_c('span',[_vm._v("Export to Excel")])])],1)]],2):_vm._e(),(_vm.init_loading == false)?_c('v-data-table',{attrs:{"headers":_vm.headers_series,"items":_vm.orgseriesdetails,"search":_vm.search_book},scopedSlots:_vm._u([{key:"item.Book_count",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"gray","dark":""},on:{"click":function($event){return _vm.getorgseriesdata(item)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.Book_count)+" ")])]}}],null,true)},[_c('span',[_vm._v(" View Details")])])]}}],null,false,1112798809)}):_vm._e()],2)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }