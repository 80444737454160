<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
      <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
      </div>
    </v-row><br><br><br />
    <section class="bg-color-">
      <div class="container">
        <template>
          <div v-if="init_loading">
            <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
          </div>
          <div v-else>
            <v-card>
              <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                  hide-details></v-text-field>
                <v-spacer></v-spacer>
                <template>
                  <download-excel :data="orgBookslist" :fields="all_org" worksheet="Books Data" name="Books Data.xls">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                      </template>
                      <span>Import to Excel</span>
                    </v-tooltip>
                  </download-excel>
                </template>
              </v-card-title>
              <!-- <template>
              <div class="text-center" v-if="circular">
                <v-progress-linear indeterminate height="25" color="#393e46">
                  <strong style="color: #fff">Loading...</strong>
                </v-progress-linear>
              </div>
            </template> -->

              <v-data-table :headers="headers" :items="orgBookslist" :search="search">
                <template v-slot:item.totslBooks="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip color="gray" v-bind="attrs" v-on="on" dark @click="orgbooksdetails(item)">
                        {{ item.totslBooks }}
                      </v-chip>
                    </template>
                    <span> View Details</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.Dept_Count="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip color="gray" v-bind="attrs" v-on="on" dark @click="getorgdeptdetails(item)">
                        {{ item.Dept_Count }}
                      </v-chip>
                    </template>
                    <span> View Details</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.Series_Count="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip color="gray" v-bind="attrs" v-on="on" dark @click="getorgseriesdetails(item)">
                        {{ item.Series_Count }}
                      </v-chip>
                    </template>
                    <span> View Details</span>
                  </v-tooltip>
                </template>
              </v-data-table>

            </v-card>
          </div>
        </template>
      </div>
    </section>
    <!-- /. section -->
    <!-- details -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_details" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="gray">
              <v-btn icon dark @click="dialog_details = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ org_name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="dialog_details = false"> Close </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <br />
            <v-card-text>
              <v-card>
                <template>
                  <div class="text-center" v-if="init_loading">
                    <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
                  </div>
                </template>
                <v-card-title v-if="init_loading == false">
                  <v-text-field v-model="search_book" append-icon="mdi-magnify" label="Search" single-line
                    hide-details></v-text-field>
                  <v-spacer></v-spacer>
                  <template>
                    <download-excel :data="orgbookdetails" :fields="single_org" worksheet="Books Data"
                      name="Books Data.xls">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                        </template>
                        <span>Export to Excel</span>
                      </v-tooltip>
                    </download-excel>
                  </template>
                </v-card-title>

                <v-data-table :headers="header_org" :items="orgbookdetails" :search="search_book"
                  v-if="init_loading == false">
                  <template v-slot:item.date_of_entry="{ item }">
                    {{ item.date_of_entry.split("T")[0].split("-")[2] + "-" +
                      item.date_of_entry.split("T")[0].split("-")[1] + "-" + item.date_of_entry.split("T")[0].split("-")[0]
                    }}
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_details_series" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="gray">
              <v-btn icon dark @click="series_close_data()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ org_name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="series_close_data()"> Close </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <br />
            <v-card-text>
              <v-card>
                <template>
                  <div class="text-center" v-if="init_loading">
                    <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
                  </div>
                </template>
                <v-card-title v-if="init_loading == false">
                  <v-text-field v-model="search_book" append-icon="mdi-magnify" label="Search" single-line
                    hide-details></v-text-field>
                  <v-spacer></v-spacer>
                  <template>
                    <download-excel :data="orgbookdetails" :fields="single_org" worksheet="Books Data"
                      name="Books Data.xls">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                        </template>
                        <span>Export to Excel</span>
                      </v-tooltip>
                    </download-excel>
                  </template>
                </v-card-title>

                <v-data-table :headers="header_org" :items="orgbookdetails" :search="search_book"
                  v-if="init_loading == false">
                  <template v-slot:item.date_of_entry="{ item }">
                    {{ item.date_of_entry.split("T")[0].split("-")[2] + "-" +
                      item.date_of_entry.split("T")[0].split("-")[1] + "-" + item.date_of_entry.split("T")[0].split("-")[0]
                    }}
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>


    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_details_dept" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="gray">
              <v-btn icon dark @click="dept_close_data()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ org_name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="dept_close_data()"> Close </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <br />
            <v-card-text>
              <v-card>
                <template>
                  <div class="text-center" v-if="init_loading">
                    <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
                  </div>
                </template>
                <v-card-title v-if="init_loading == false">
                  <v-text-field v-model="search_book" append-icon="mdi-magnify" label="Search" single-line
                    hide-details></v-text-field>
                  <v-spacer></v-spacer>
                  <template>
                    <download-excel :data="orgbookdetails" :fields="single_org" worksheet="Books Data"
                      name="Books Data.xls">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                        </template>
                        <span>Export to Excel</span>
                      </v-tooltip>
                    </download-excel>
                  </template>
                </v-card-title>

                <v-data-table :headers="header_org" :items="orgbookdetails" :search="search_book"
                  v-if="init_loading == false">
                  <template v-slot:item.date_of_entry="{ item }">
                    {{ item.date_of_entry.split("T")[0].split("-")[2] + "-" +
                      item.date_of_entry.split("T")[0].split("-")[1] + "-" + item.date_of_entry.split("T")[0].split("-")[0]
                    }}
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_department" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="gray">
              <v-btn icon dark @click="dialog_department = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ org_name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="dialog_department = false">
                  Close
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <br />
            <v-card-text>
              <v-card>
                <template>
                  <div class="text-center" v-if="init_loading">
                    <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
                  </div>
                </template>
                <v-card-title v-if="init_loading == false">
                  <v-text-field v-model="search_book" append-icon="mdi-magnify" label="Search" single-line
                    hide-details></v-text-field>
                  <v-spacer></v-spacer>
                  <template>
                    <download-excel :data="orgdeptdetails" :fields="dept_book_count_excel" worksheet="Books Data"
                      name="Books Data.xls">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                        </template>
                        <span>Export to Excel</span>
                      </v-tooltip>
                    </download-excel>
                  </template>
                </v-card-title>

                <v-data-table :headers="headers_department" :items="orgdeptdetails" :search="search_book"
                  v-if="init_loading == false">
                  <template v-slot:item.Book_count="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip color="gray" v-bind="attrs" v-on="on" dark @click="getorgdeptdata(item)">
                          {{ item.Book_count }}
                        </v-chip>
                      </template>
                      <span> View Details</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_series" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="gray">
              <v-btn icon dark @click="dialog_series = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ org_name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="dialog_series = false"> Close </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <br />
            <v-card-text>
              <v-card>
                <template>
                  <div class="text-center" v-if="init_loading">
                    <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
                  </div>
                </template>
                <v-card-title v-if="init_loading == false">
                  <v-text-field v-model="search_book" append-icon="mdi-magnify" label="Search" single-line
                    hide-details></v-text-field>
                  <v-spacer></v-spacer>
                  <template>
                    <download-excel :data="orgseriesdetails" :fields="series_book_count_excel" worksheet="Books Data"
                      name="Books Data.xls">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                        </template>
                        <span>Export to Excel</span>
                      </v-tooltip>
                    </download-excel>
                  </template>
                </v-card-title>

                <v-data-table :headers="headers_series" :items="orgseriesdetails" :search="search_book"
                  v-if="init_loading == false">
                  <template v-slot:item.Book_count="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip color="gray" v-bind="attrs" v-on="on" dark @click="getorgseriesdata(item)">
                          {{ item.Book_count }}
                        </v-chip>
                      </template>
                      <span> View Details</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
  <!-- /. root div -->
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data: () => ({
    circular: false,
    orgBookslist: [],
    orgbookdetails: [],
    orgdeptdetails: [],
    orgseriesdetails: [],
    loading: true,
    snackbar_msg: "",
    init_loading: false,
    color: "",
    snackbar: false,
    search: "",
    search_book: "",
    org_name: null,
    dialog_details_series: false,
    dialog_details_dept: false,
    dialog_details: false,
    dialog_department: false,
    dialog_series: false,
    maxauthor: 0,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },

      {
        text: 'Total Books',
        disabled: true,
        href: '',
      },
    ],
    headers: [
      { text: "Sr. No.", value: "srno" },
      { text: "Organization", value: "orgName" },
      { text: "Number of Books", value: "totslBooks" },
      { text: "Dept Count", value: "Dept_Count" },
      { text: "Series Count", value: "Series_Count" },
    ],
    headers_department: [
      { text: "Sr. No.", value: "srno" },
      { text: "Library Department", value: "deptname" },
      { text: "Book Count", value: "Book_count" },
    ],
    headers_series: [
      { text: "Sr. No.", value: "srno" },
      { text: "Series", value: "seriesname" },
      { text: "Book Count", value: "Book_count" },
    ],

    header_org: [
      { text: "Sr. No.", value: "srno" },
      { text: "Display Order", value: "display_order" },
      { text: "Organization", value: "orgName" },
      { text: "Accession Number", value: "accession_number" },
      { text: "Date Of Entry", value: "date_of_entry" },
      { text: "Barcode", value: "barcode" },
      { text: "ISBN", value: "isbn" },
      { text: "Title", value: "title" },
      { text: "Author1", value: "author1" },
      { text: "Author2", value: "author2" },
      { text: "Author3", value: "author3" },
      { text: "Author4", value: "author4" },
      { text: "Author5", value: "author5" },
      { text: "Edition", value: "edition" },
      { text: "Medium", value: "medium" },
      { text: "Subject", value: "subject" },
      { text: "Classification NO", value: "cno" },
      { text: "Series", value: "Series" },
      { text: "Dept", value: "dept" },
      { text: "Pages", value: "pages" },
      // { text: "Book Format", value: "bookformat" },
      { text: "Book Type", value: "booktype" },
      // { text: "Book Category", value: "bookcategory" },
      { text: "Publisher", value: "publisher" },
      { text: "Publish year", value: "publisher_year" },
      { text: "Localtion", value: "location" },
      { text: "Bill NO", value: "billno" },
      { text: "Bill Date", value: "purchaseDate" },
      { text: "Price", value: "actualprice" },
      { text: "Discount", value: "discount" },
      { text: "Net Price", value: "price" },
      { text: "Vendor", value: "vendor" },
      { text: "Remarks", value: "remark" },

    ],

    single_org: {



      Sr_No: "srno",
      display_order: "display_order",
      Orgnization: "orgName",
      Date_Of_Entry: "date_of_entry",
      "Date_Of_Entry": {
        field: "date_of_entry",
        callback: (item) => {
          return item == ""
            ? ""
            : item.split("T")[0].split("-")[2] +
            "-" +
            item.split("T")[0].split("-")[1] +
            "-" +
            item.split("T")[0].split("-")[0];
        },
      },
      Accession_Number: "accession_number",
      Barcode: "barcode",
      ISBN: "isbn",
      Classification_NO: "cno",
      Title: "title",
      Author1: "author1",
      Author2: "author2",
      Author3: "author3",
      Author4: "author4",
      Author5: "author5",
      Edition: "edition",
      Series: "Series",

      Dept: "dept",
      Pages: "pages",
      Medium: "medium",
      Book_Format: "bookformat",
      Book_Type: "booktype",
      Book_Category: "bookcategory",
      Publisher: "publisher",
      Publish_Year: "publisher_year",
      Display_Order: "display_order",
      Localtion: "location",
      Bill_NO: "billno",
      Bill_Date: "purchaseDate",
      Price: "price",
      Discount: "discount",
      Net_Price: "actualprice",
      Remarks: "remark",
      Vendor: "vendor",

    },
    all_org: {
      "Sr. No.": "srno",
      Orgnization: "orgName",
      "Number of Books": "totslBooks",
      "Number of Dept": "Dept_Count",
      "Number of Series": "Series_Count",
    },

    dept_book_count_excel: {
      "Sr. No.": "srno",
      "Library Department": "deptname",
      "Book Count": "Book_count",
    },
    series_book_count_excel: {
      "Sr. No.": "srno",
      "Series": "seriesname",
      "Book Count": "Book_count",
    },
  }),
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.init_loading = true;
      axios
        .post("/Management/getAllOrgBooksData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.orgBookslist = res.data.orgbooklist;
            this.init_loading = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },

    getorgseriesdetails(item) {
      const data = {
        orgid: item.orgid,
      };
      this.init_loading = true;
      axios
        .post("/Management/getorgseriesdetails", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.loading = false;
            this.orgseriesdetails = res.data.orgseriesdetails;
            this.org_name = res.data.orgName;
            this.dialog_series = true;
            this.init_loading = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },

    getorgseriesdata(item) {
      console.log(item)
      const data = {
        orgid: item.orgid,
        series_id: item.series_id
      };
      this.dialog_series = false;
      this.init_loading = true;
      axios
        .post("/Management/getorgseriesdata", data)
        .then((res) => {
          if (res.data.msg == "200") {

            this.init_loading = false;
            this.loading = false;
            this.orgbookdetails = res.data.orgbookdetails;
            this.maxauthor = res.data.maxauther;
            this.org_name = res.data.orgName;
            this.dialog_details_series = true;

          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },

    getorgdeptdata(item) {
      console.log(item)
      const data = {
        orgid: item.orgid,
        dept_id: item.dept_id
      };
      this.dialog_department = false;
      this.init_loading = true;
      axios
        .post("/Management/getorgdeptdata", data)
        .then((res) => {
          if (res.data.msg == "200") {

            this.init_loading = false;
            this.loading = false;
            this.orgbookdetails = res.data.orgbookdetails;
            this.maxauthor = res.data.maxauther;
            this.org_name = res.data.orgName;
            this.dialog_details_dept = true;

          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    series_close_data() {
      this.dialog_details_series = false;
      this.dialog_series = true;
    },
    dept_close_data() {
      this.dialog_details_dept = false;
      this.dialog_department = true;
    },
    getorgdeptdetails(item) {
      const data = {
        orgid: item.orgid,
      };
      this.init_loading = true;
      axios
        .post("/Management/orgdeptdetails", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.loading = false;
            this.orgdeptdetails = res.data.orgdeptdetails;
            this.org_name = res.data.orgName;
            this.dialog_department = true;
            this.init_loading = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    orgbooksdetails(item) {
      const data = {
        orgid: item.orgid,
      };
      this.init_loading = true;
      axios
        .post("/Management/getOrgBooksDetails", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.loading = false;
            this.orgbookdetails = res.data.orgbookdetails;
            this.maxauthor = res.data.maxauther;
            this.org_name = res.data.orgName;
            this.dialog_details = true;
            this.init_loading = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
  watch: {},
};
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1024px) {}
</style>
